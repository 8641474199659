import { chAxios } from '@/shared/lib/axios';
import type { Paging } from '@/shared/types/static-types';

export async function adminDelete(objectModelType: string, objectId: string) {
  /*
      objectModelType   Accepted values: see "targetModels" in @/shared/services/constants
      objectId          should be the UUID of the item to be deleted
    */
  const url = `/admin/del/`;
  const dataOut = {
    id: objectId,
    type: objectModelType,
  };
  try {
    const { data } = await chAxios().post(url, dataOut);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getNextPage(paging: Paging) {
  const url = paging.next;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getLatestReports(category?: string) {
  const url = `/content-reports/?report_category=${category || ''}`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getLatestReportsOfUser(userId?: string, page = 1) {
  const url = `/content-reports/?reported_user=${userId}&page=${page}`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getUserModLog(user: string) {
  const url = `/mod/user-mod-logs/?user__id=${user}`;
  const { data } = await chAxios().get(url);
  if (data.count === 0) return null;
  return data.results[0];
}

export async function getUserModLogs() {
  const url = `/mod/user-mod-logs/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function banUser(user: string, reason: string, days: number) {
  const url = `/mod/ban/`;
  const res = await chAxios().post(url, { user, reason, days });
  return res;
}

export async function unbanUser(user: string) {
  const url = `/mod/unban/`;
  const res = await chAxios().post(url, { user });
  return res;
}

export async function getUserLatestActivities(user: string) {
  const url = `/mod/user-activities/${user}/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function saveReport(report: any) {
  const url = `/content-reports/${report.id}/`;
  const { data } = await chAxios().patch(url, report);
  return data;
}

export async function saveUserModLog(log: any) {
  const url = `/mod/user-mod-logs/${log.id}/`;
  const { data } = await chAxios().patch(url, log);
  return data;
}

export async function forceUserProfileNSFW(user: string, newVal: boolean) {
  const url = `/mod/force-profile-nsfw/`;
  const { data } = await chAxios().post(url, { user, is_nsfw: newVal });
  return data;
}

export async function unforceUserProfileNSFW(user: string) {
  const url = `/mod/unforce-profile-nsfw/`;
  const { data } = await chAxios().post(url, { user });
  return data;
}

export async function forceUserShowNSFW(user: string, newVal: boolean) {
  const url = `/mod/force-show-nsfw/`;
  const { data } = await chAxios().post(url, { user, show_nsfw: newVal });
  return data;
}

export async function unforceUserShowNSFW(user: string) {
  const url = `/mod/unforce-show-nsfw/`;
  const { data } = await chAxios().post(url, { user });
  return data;
}

export async function sendEmail(user: string, email: string, severity: 'info' | 'warn') {
  const url = `/mod/send-email/`;
  const { data } = await chAxios().post(url, { user, email, severity });
  return data;
}

export async function downgradeCharacterPrivacy(user: string, privacy: 'M' | 'U') {
  const url = `/mod/character-privacy/`;
  const { data } = await chAxios().post(url, { user, privacy });
  return data;
}
