<template>
  <div class="userModLog-card" v-if="userModLog">
    <div class="userModLog-card__content">
      <div class="mb-1">
        <span class="userModLog-card__title">{{ userModLog.user?.username }}</span>
        has been reported {{ userModLog.report_count }} time(s)
      </div>
      <span :title="`${formatDateTime(userModLog.modified)} UTC`" class="text-gray">
        Last updated {{ formatTimeAgo(userModLog.modified) }}
      </span>
      <p class="mb-0 bold red" v-if="userModLog.user?.banned_until">Ban ends {{ formatTimeAgo(userModLog.user?.banned_until) }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UserModLog } from '@/shared/types/static-types';
import { formatDateTime, formatTimeAgo } from '@/shared/utils/dateTime';
import { adminStore } from '@/shared/pinia-store/admin';

const props = defineProps({
  userModLogId: String
});
const userModLogId = toRef(props.userModLogId as string);
const userModLog = computed(() => {
  if (!userModLogId.value) return null;
  const { getUserModLogById } = adminStore();
  return getUserModLogById.value(userModLogId.value);
});

</script>

<style lang="sass" scoped>
.userModLog-card
  padding: 1rem
  border: 1px solid #ccc
  border-radius: 0.5rem
  margin-bottom: 1rem
  background-color: #f9f9f9
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)
  display: flex
  justify-content: space-between
  align-items: center
  .userModLog-card__content
    flex: 1
    .userModLog-card__title
      font-weight: bold
    .userModLog-card__description
      font-size: 1rem
  .userModLog-card__actions
    display: flex
    gap: 1rem
    .userModLog-card__action
      padding: 0.5rem 1rem
      border-radius: 0.25rem
      background-color: #007bff
      color: white
      cursor: pointer
      transition: background-color 0.2s
      &:hover
        background-color: #0056b3
.red
  color: red
</style>
