<template>
  <div v-if="currentLog">
    <span class="text-blue clickable-item-hov" @click.prevent="close">
      Back
    </span>
    <h6 class="title mt-3">
      Review User Mod Log for @{{ currentLog?.user?.username }}
    </h6>
    <p v-if="!!currentUser.banned_until" class="red my-0">Is Currently Banned (Ends {{ formatTimeAgo(currentUser.banned_until) }})</p>
    <div class="my-3">
      <label for="moderator-notes">Moderator Notes (visible to mods only)</label>
      <ion-button class="mb-3 ml-2" @click="save"><span class="no-select">Save</span></ion-button>
      <ion-textarea
        :value="currentLog.comments" 
        type="text"
        placeholder="Enter notes here"
        size="40"
        class="c-textarea"
        @change="updateLocalUserModLogComments"
      />
    </div>
    <hr>
    <div class="mt-3">
      <h4 class="title mt-3">Moderation History</h4>
      <ul>
        <li v-for="row in currentLog.extra?.mod_history">
          <span v-if="row.banned_on">{{ formatDate(row.banned_on) }}: Banned for {{ row.ban_duration }} days by {{ row.by || 'n/a'}}. Reason: {{ row.reason || '-'}}</span>
          <span v-if="row.event === 'sent_email'">{{ row.at ? formatDate(row.at) : '(No date)'}}: {{ row.by || 'n/a' }} sent email ({{ row.severity }}). Text: {{ row.text }}</span>
        </li>
      </ul>
    </div>
    <hr>
    <h4 class="title mt-3">Actions</h4>
    <div class="my-2">

      <p class="mb-1">Force user <code style="background-color: pink; padding:0.1rem">is_nsfw</code> state</p>
      <span>(Mark the user's profile as NSFW)</span><br>
      <ion-button 
        @click="forceUserProfileNSFW(true)"
        :class="{ selected: currentUser.restrictions.is_nsfw === true}"
        :color="currentUser.restrictions.is_nsfw === true ? 'secondary' : 'primary'"
      >
        Force ON
      </ion-button>
      <ion-button
        @click="forceUserProfileNSFW(false)"
        :class="{ selected: currentUser.restrictions.is_nsfw === false}"
        :color="currentUser.restrictions.is_nsfw === false ? 'secondary' : 'primary'"
      >
        Force OFF
      </ion-button>
      <ion-button
        @click="unforceUserProfileNSFW" 
        v-if="currentUser.restrictions?.is_nsfw === true || currentUser.restrictions?.is_nsfw === false"
      >
        Remove restriction
      </ion-button>

      <p class="mb-1">Force user <code style="background-color: pink; padding:0.1rem">show_nsfw</code> state</p>
      <span>(Can user see NSFW content)</span><br>
      <ion-button
        @click="forceShowNSFW(true)"
        :class="{ selected: currentUser.restrictions.show_nsfw === true}"
        :color="currentUser.restrictions.show_nsfw === true ? 'secondary' : 'primary'"
      >Force ON</ion-button>
      <ion-button
        @click="forceShowNSFW(false)"
        :class="{ selected: currentUser.restrictions.show_nsfw === false}"
        :color="currentUser.restrictions.show_nsfw === false ? 'secondary' : 'primary'"
      >
        Force OFF
      </ion-button>
      <ion-button
        @click="unforceShowNSFW"
        v-if="currentUser.restrictions?.show_nsfw === true || currentUser.restrictions?.show_nsfw === false"
      >
        Remove restriction
      </ion-button>

      <p class="mb-1">Downgrade <code style="background-color: pink; padding:0.1rem">privacy</code> for all of this user's characters</p>
      <ion-button
        @click="setUserCharacterPrivacy('U')"
        color="danger"
      >Unlist all public characters</ion-button>
      <ion-button
        @click="setUserCharacterPrivacy('M')"
        color="danger"
      >Make all characters private</ion-button>
    </div>
    <hr>
    <div class="mt-3">
      <h4 class="title mt-3">Ban User</h4>
      <label for="ban-reason">Reason for Ban&nbsp;</label>
      <ion-textarea 
        class="c-textarea" 
        v-model="banReason" 
        type="text" 
        placeholder="Enter reason here (will be shown to user)"
      />
    </div>
    <ion-button @click="ban(3)"><span class="no-select">3 day ban</span></ion-button>
    <ion-button @click="ban(7)"><span class="no-select">7 day ban</span></ion-button>
    <ion-button @click="ban(36500)"><span class="no-select">Perma ban</span></ion-button>
    <ion-button v-if="currentUser.banned_until" @click="unban"><span class="no-select">Unban</span></ion-button>
    <div class="mt-3">
      <h4 class="title mt-3">Email User</h4>
      <ion-textarea 
        class="c-textarea" 
        v-model="emailText" 
        type="text" 
        placeholder="Enter email text here"
      />
    </div>
    <ion-button @click="sendModMail('info')"><span class="no-select">Send Informational email</span></ion-button>
    <ion-button @click="sendModMail('warn')" color="danger"><span class="no-select">Send Warning Email</span></ion-button>
    <hr>
    <div>
      <h4>User has been reported {{ reportsOnUser.length }} time(s)</h4>
      <div>
        <ReportCard :report="report" v-for="report in reportsOnUser" :key="report.id" />
        <div v-if="userReportPagingHasNextPage">Load More</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Report, User, UserModLog } from '@/shared/types/static-types';
import { adminStore } from '@/shared/pinia-store/admin';
import { sendEmail, downgradeCharacterPrivacy } from '@/shared/actions/admin';
import ReportCard from '@/shared/pages/moderation/components/ReportCard.vue';
import { toast } from '@/shared/native';
import { formatDate, formatTimeAgo } from '@/shared/utils/dateTime';
import logger from '@/shared/services/logger';
const { updateLocalUserModLog } = adminStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  userModLog: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['close']);
const emailText = ref('');
const userModLogId = toRef(props, 'userModLog');
const banReason = ref('');
const close = () => {
  emits('close');
};

const updateLocalUserModLogComments = (e: Event) => {
  if (!currentLog.value) return;
  const target = e.target as HTMLInputElement;
  updateLocalUserModLog(currentLog.value.id!, { comments: target.value });
};

const currentUser = computed(() => {
  return currentLog.value?.user as User;
});

const currentLog = computed(() => {
  if (!userModLogId.value) return null;
  const { getUserModLogById } = adminStore();
  return getUserModLogById.value(userModLogId.value);
});

const userReportPagingHasNextPage = computed(() => {
  const { userReportPagingHasNextPage: u } = adminStore();
  return u.value;
});

const reportsOnUser = computed(() => {
  if (!userModLogId.value) return [];
  const { getReportsByUserId } = adminStore();
  const res =  getReportsByUserId.value(currentUser.value?.id);
  return res
});

onMounted(() => {
  if (!currentLog.value) return;
  const { fetchReportsOnUser } = adminStore();
  fetchReportsOnUser(currentLog.value.user?.id);
});

const ban = async (days: number) => {
  const { banUser } = adminStore();
  if (!currentLog.value) return;
  if (!banReason.value?.trim()) {
    toast.show('Please enter a reason for the ban', 'nonative', 'danger');
  }
  // confirm first
  const confirmBan = confirm(
    `Are you sure you want to ban ${currentLog.value.user.username} for ${days ? days : '999999'} day(s)?`
    );
  if (!confirmBan) {
    toast.show('No action taken', 'nonative', 'primary');
    return;
  }
  const data = await banUser(currentLog.value.user.id, banReason.value, days);
  toast.show('User has been banned', 'nonative', 'danger');
  banReason.value = '';
}

const unban = async () => {
  const { unbanUser } = adminStore();
  if (!currentLog.value) return;
  // confirm first
  const confirmUnban = confirm(
    `Are you sure you want to unban ${currentLog.value.user.username}?`
    );
  if (!confirmUnban) {
    toast.show('No action taken', 'nonative', 'primary');
    return;
  }
  unbanUser(currentLog.value.user.id);
}

const save = () => {
  if (!currentLog.value) return;
  const { saveUserModLog } = adminStore();
  saveUserModLog(currentLog.value);
}

const forceUserProfileNSFW = async (setting: boolean) => {
  if (!currentLog.value) return;
  const { forceUserProfileNSFW: forceNSFW } = adminStore();
  await forceNSFW(currentLog.value.user.id, setting);
}

const unforceUserProfileNSFW = async () => {
  if (!currentLog.value) return;
  const { unforceUserProfileNSFW: unforceNSFW } = adminStore();
  await unforceNSFW(currentLog.value.user.id);
}

const forceShowNSFW = async (setting: boolean) => {
  if (!currentLog.value) return;
  const { forceUserShowNSFW: forceShow } = adminStore();
  await forceShow(currentLog.value.user.id, setting);
}

const unforceShowNSFW = async () => {
  if (!currentLog.value) return;
  const { unforceUserShowNSFW: unforceShow } = adminStore();
  await unforceShow(currentLog.value.user.id);
}

const setUserCharacterPrivacy = async (privacy: 'M' | 'U') => {
  const confirmSend = confirm(
    `Are you sure you want downgrade ${currentLog.value!.user.username}'s characters' privacy to ${privacy}?`
  );
  if (!confirmSend) {
    toast.show('No action taken', 'nonative', 'primary');
    return;
  }
  await downgradeCharacterPrivacy(currentLog.value!.user.id, privacy);
  toast.show('Character privacy downgraded', 'nonative', 'primary');
}

const sendModMail = async (severity: 'info' | 'warn') => {
  if (!currentLog.value) return;
  const confirmSend = confirm(
    `Are you sure you want to send this email (${severity}) to ${currentLog.value.user.username}?`
  );
  if (!confirmSend) {
    toast.show('No action taken', 'nonative', 'primary');
    return;
  }
  try {
    await sendEmail(currentLog.value.user.id, emailText.value, severity);
    toast.show('Email sent', 'nonative', 'primary');
    emailText.value = '';
  } catch (e) {
    toast.show('Failed to send email. Error has been logged.', 'nonative', 'danger');
    logger.error({
      e,
      loc: 'ReviewUserModLogPartial.sendModMail',
      msg: 'sendModMail Error',
      data: {
        severity,
        emailText: emailText.value,
        user: currentLog.value.user.id,
      },
    });
  }
}
</script>

<style lang="sass" scoped>
.close-button
  width: 50px
.no-space
  margin-left: 0
  margin-right: 0
  margin-bottom: 0
.no-space [class*='col-']:not(:first-child),
.no-space [class*='col-']:not(:last-child)
  padding-right: 0
  padding-left: 0
.see-all-btn
  --border-radius: 10px
  height: 30px
.row
  margin-top: 10px !important
.one-character
  img
    min-width: 90px
    width: 90px !important
    height: 90px !important
    border-radius: 45px
    border: solid gray 0.1px
    object-fit: cover
    .name
      font-size: 18px
      font-weight: bold
      color: #737373
      cursor: pointer
    .name:hover
      opacity: 0.7
.title
  font-weight: bold
  font-size: 22px
.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 95vh
  overflow: auto
.red
  color: red
</style>
