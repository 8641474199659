import { defineStore, storeToRefs } from 'pinia';
import { toast } from '../native/toast';
import { Paging, Report, UserModLog } from '@/shared/types/static-types';
import {
  getLatestReports,
  getUserModLogs,
  getUserModLog,
  banUser as callBanUser,
  unbanUser as callUnbanUser,
  getLatestReportsOfUser,
  saveUserModLog as callSaveUserModLog,
  saveReport as callSaveReport,
  forceUserProfileNSFW as callForceUserProfileNSFW,
  unforceUserProfileNSFW as callUnforceUserProfileNSFW,
  forceUserShowNSFW as callForceUserShowNSFW,
  unforceUserShowNSFW as callUnforceUserShowNSFW,
} from '@/shared/actions/admin';
import { getUserReportCategories } from '@/shared/actions/report';
import { getNextPage } from '@/shared/helpers/pagination';

export interface IAdminState {
  _reports: Report[];
  _reportsByReportedUser: Record<string, Report[]>;
  _userModLogs: UserModLog[];
  _categories: any[];
  _isLoading: boolean;
  _paging: Paging;
  _userReportPaging: Paging;
  _filterTabIndex: number;
}

const admin = defineStore('admin', {
  state: (): IAdminState => ({
    _reports: [],
    _reportsByReportedUser: {}, // { id : Report[] }
    _userModLogs: [],
    _categories: [],
    _isLoading: false,
    _paging: { page: 1, next: '', previous: '', count: 0 },
    _userReportPaging: { page: 1, next: '', previous: '', count: 0 },
    _filterTabIndex: -1,
  }),
  actions: {
    reset() {
      this._reports = [];
      this._reportsByReportedUser = {};
      this._userModLogs = [];
      this._categories = [];
      this._isLoading = false;
      this._paging = { page: 1, next: '', previous: '', count: 0 };
      this._userReportPaging = { page: 1, next: '', previous: '', count: 0 };
      this._filterTabIndex = -1;
    },

    async initLoad() {
      this._isLoading = true;
      try {
        this._categories = await getUserReportCategories();
        const paging = await getUserModLogs();
        this._paging = paging as Paging;
        this._userModLogs = paging.results as UserModLog[];
      } catch (e) {
      } finally {
        this._isLoading = false;
      }
    },
    async onPressCategoryTab(filterIndex: number) {
      if (this._filterTabIndex === filterIndex) {
        this._filterTabIndex = -1;
        const data = await getUserModLogs();
        this._paging = data as Paging;
        this._userModLogs = data.results as UserModLog[];
        return;
      }
      this._filterTabIndex = filterIndex;
      const category = this._categories[filterIndex];
      if (category) {
        this._paging = (await getLatestReports(category.id)) as Paging;
        this._reports = this._paging.results as Report[];
      }
    },
    async loadMoreUserModLogs() {
      if (!this._paging.next) return;
      this._isLoading = true;
      try {
        const data = await getNextPage(this._paging);
        this._paging = data;
        this._userModLogs = [...this._userModLogs, ...data.results];
      } catch (e) {
        toast.show('Error loading more userModLogs', 'nonative', 'danger');
      } finally {
        this._isLoading = false;
      }
    },
    async loadMoreReports() {
      if (!this._paging.next) return;
      this._isLoading = true;
      try {
        const reports = await getNextPage(this._paging);
        this._reports = [...this._reports, ...reports];
      } catch (e) {
        toast.show('Error loading more reports', 'nonative', 'danger');
      } finally {
        this._isLoading = false;
      }
    },
    async fetchUserModLogByUserId(userId: string) {
      const modLog = await getUserModLog(userId);
      if (modLog) {
        this._userModLogs = this._userModLogs.filter((x) => {
          return x.id !== modLog.id;
        });
        this._userModLogs = [modLog, ...this._userModLogs];
      } else {
        toast.show('No mod log found. User likely has not been reported', 'nonative', 'danger');
      }
    },
    async fetchReportsOnUser(userId: string) {
      this._isLoading = true;
      try {
        const paging = (await getLatestReportsOfUser(userId)) as Paging;
        const reports = paging.results as Report[];
        this._reportsByReportedUser[userId] = reports;
        this._userReportPaging = paging;
      } catch (e) {
        toast.show('Error loading reports for user', 'nonative', 'danger');
      } finally {
        this._isLoading = false;
      }
    },
    async fetchNextPageReportsOnUser(userId: string) {
      if (!this._userReportPaging.next) return;
      this._isLoading = true;
      try {
        const paging = (await getNextPage(this._userReportPaging)) as Paging;
        const reports = paging.results as Report[];
        this._reportsByReportedUser[userId] = [...this._reportsByReportedUser[userId], ...reports];
        this._userReportPaging = paging;
      } catch (e) {
        toast.show('Error loading more reports', 'nonative', 'danger');
      } finally {
        this._isLoading = false;
      }
    },
    async banUser(userId: string, reason: string, duration: number) {
      const res = await callBanUser(userId, reason, duration);
      if (res.status === 200) {
        toast.show(`User banned (${duration} days)`, 'nonative', 'danger');
        const index = this._userModLogs.findIndex((log: UserModLog) => log.user.id === userId);
        this._userModLogs[index].user.banned_until = res.data.banned_until;
      } else {
        toast.show('Error banning user', 'nonative', 'danger');
      }
    },
    async unbanUser(userId: string) {
      const res = await callUnbanUser(userId);
      if (res.status === 200) {
        toast.show('User unbanned', 'nonative', 'primary');
        const index = this._userModLogs.findIndex((log: UserModLog) => log.user.id === userId);
        this._userModLogs[index].user.banned_until = undefined;
        this._userModLogs[index].user.is_banned = false;
      } else {
        toast.show('Error unbanning user', 'nonative', 'danger');
      }
    },
    updateLocalUserModLog(userModLogId: string, updatedFields: any) {
      const index = this._userModLogs.findIndex((log: UserModLog) => log.id === userModLogId);
      if (index !== -1) {
        this._userModLogs[index] = { ...this._userModLogs[index], ...updatedFields };
      }
    },
    updateLocalReport(reportId: string, updatedFields: any) {
      const index1 = this._reports.findIndex((r: Report) => r.id === reportId);
      if (index1 !== -1) {
        this._reports[index1] = { ...this._reports[index1], ...updatedFields };
      }
    },
    async saveReport(report: Report) {
      await callSaveReport(report);
      toast.show('Saved', 'nonative', 'primary');
    },
    async saveUserModLog(userModLog: UserModLog) {
      await callSaveUserModLog(userModLog);
      toast.show('Saved', 'nonative', 'primary');
    },
    async forceUserProfileNSFW(userId: string, newVal: boolean) {
      await callForceUserProfileNSFW(userId, newVal);
      const index = this._userModLogs.findIndex((log: UserModLog) => log.user.id === userId);
      this._userModLogs[index].user.restrictions = { ...this._userModLogs[index].user.restrictions, is_nsfw: newVal };
      toast.show('User profile NSFW setting forced', 'nonative', 'success');
    },
    async unforceUserProfileNSFW(userId: string) {
      await callUnforceUserProfileNSFW(userId);
      const index = this._userModLogs.findIndex((log: UserModLog) => log.user.id === userId);
      delete this._userModLogs[index].user.restrictions['is_nsfw'];
      toast.show('User profile NSFW setting restriction removed', 'nonative', 'success');
    },
    async forceUserShowNSFW(userId: string, newVal: boolean) {
      await callForceUserShowNSFW(userId, newVal);
      const index = this._userModLogs.findIndex((log: UserModLog) => log.user.id === userId);
      this._userModLogs[index].user.restrictions = { ...this._userModLogs[index].user.restrictions, show_nsfw: newVal };
      toast.show('Show NSFW setting forced', 'nonative', 'success');
    },
    async unforceUserShowNSFW(userId: string) {
      await callUnforceUserShowNSFW(userId);
      const index = this._userModLogs.findIndex((log: UserModLog) => log.user.id === userId);
      delete this._userModLogs[index].user.restrictions['show_nsfw'];
      toast.show('Show NSFW setting restriction removed', 'nonative', 'success');
    },
  },
  getters: {
    reports(): Report[] {
      return this._reports;
    },
    userModLogs(): UserModLog[] {
      return this._userModLogs;
    },
    getUserModLogById: (state) => {
      return (id: string) => state._userModLogs.find((x) => x.id === id);
    },
    getReportById: (state) => {
      return (reportId: string) => state._reports.find((report) => report.id === reportId);
    },
    getReportsByUserId: (state) => {
      return (userId: string) => {
        return state._reportsByReportedUser[userId] || [];
      };
    },
    userReportPagingHasNextPage: (state) => {
      return state._userReportPaging && !!state._userReportPaging.next;
    },
    isLoading(): boolean {
      return this._isLoading;
    },
    categories(): any[] {
      return this._categories;
    },
    currentPagingCount(): number {
      return this._paging.count;
    },
    currentPagingHasNext(): boolean {
      return !!this._paging.next;
    },
    currentCategory(): string {
      if (this._filterTabIndex === -1) return '';
      return this._categories?.[this._filterTabIndex];
    },
  },
});

export const adminStore = () => {
  const store = admin();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
