<template>
  <div class="report-card no-select" v-if="report">
    <ion-badge
      v-if="report.evaluation"
      :color="reportColor"
      class="report-badge"
    >{{report.evaluation}}</ion-badge>
    <div class="report-card__content">
      <div class="report-card__title mb-1">{{ report.reported_user?.username }}</div>
      <div class="report-card__description mb-1">
        <p class="text-gray">Report Category: {{ report.report_category?.title }}</p>
        Reporting user <span v-if="report.reported_by">(<a target="_blank" :href="userLink">{{report.reported_by?.username}}</a>)</span> said: {{ report.comments || '(No description)' }}
      </div>
      <div :title="`${formatDateTime(report.modified)} UTC`" class="my-1">
        <span class="text-gray">Last updated {{ formatTimeAgo(report.modified) }}</span>
      </div>
      <a :href="report.reported_content_link" target="_blank" rel="noopener noreferrer" @click.stop>
        <ion-button color="danger" class="nested-btn">
          <span class="no-select">View Reported Content</span>
        </ion-button>
      </a>
      <a :href="report.reported_content_admin_link" target="_blank" rel="noopener noreferrer" @click.stop>
        <ion-button color="danger" class="nested-btn">
          <span class="no-select">View in Admin Panel</span>
        </ion-button>
      </a>
      <ion-button @click.stop="openEvalModal" class="nested-btn no-select">Evaluate</ion-button>
    </div>
    <ion-modal :is-open="showEvalModal" ref="modal" trigger="open-modal" @willDismiss="closeEvalModal">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="evalReport" size="large" color="primary">
              Save
            </ion-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button @click="closeEvalModal" >
              <i slot="icon-only" name="close" class="ti-close" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list>
          <ion-item>
            <ion-label>Report Evaluation</ion-label>
            <ion-select v-model="report.evaluation">
              <ion-select-option value="no_issue">No Issue</ion-select-option>
              <ion-select-option value="mild">Mild</ion-select-option>
              <ion-select-option value="moderate">Moderate</ion-select-option>
              <ion-select-option value="bad">Bad</ion-select-option>
              <ion-select-option value="inexcusable">Inexcusable</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-textarea label="User report" :value="report.comments || 'No comment'" disabled></ion-textarea>
          </ion-item>
          <ion-item>
            <ion-textarea label="Admin comments" v-model="report.reviewer_comments" auto-grow></ion-textarea>
          </ion-item>
          <div class="text-center mt-3">
            <ion-button @click="evalReport" size="large" color="primary">
              Save
            </ion-button>
          </div>
        </ion-list>
      </ion-content>
    </ion-modal>
  </div>
</template>

<script lang="ts" setup>
import { Report } from '@/shared/types/static-types';
import { formatDateTime, formatTimeAgo } from '@/shared/utils/dateTime';
import { adminStore } from '@/shared/pinia-store/admin';

const props = defineProps({
  report: Object as () => Report
});
const showEvalModal = ref(false);
const report = toRef(props.report as Report);
const categoryStr = (categoryId: string | undefined) => {
  const { categories } = adminStore();
  if (!categoryId) return '(No category)';
  return categories.value.find((c: any) => c.id === categoryId)?.title;
}
const reportColor = computed(() => {
  if (report.value.evaluation === 'no_issue') return 'medium';
  if (report.value.evaluation === 'bad') return 'warning';
  if (report.value.evaluation === 'inexcusable') return 'danger';
  if (report.value.evaluation === 'mild') return 'secondary';
  if (report.value.evaluation === 'moderate') return 'tertiary';
  return 'light';
});

const evalReport = () => {
  const { saveReport } = adminStore();
  saveReport(report.value);
  closeEvalModal();
}

const baseUrl = computed(() => {
  const {
    public: { hostUrl },
  } = useRuntimeConfig();
  return hostUrl
});

const userLink = computed(() => {
  return `${baseUrl.value}/profile/${report.value.reported_by?.username}`;
});

const openEvalModal = () => {
  showEvalModal.value = true;
}

const closeEvalModal = () => {
  showEvalModal.value = false;
}

onUnmounted(() => {
  closeEvalModal();
});
</script>

<style lang="sass" scoped>
.report-card
  position: relative
  padding: 1rem
  border: 1px solid #ccc
  border-radius: 0.5rem
  margin-bottom: 1rem
  background-color: #f9f9f9
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)
  display: flex
  justify-content: space-between
  align-items: center
  .report-card__content
    flex: 1
    .report-card__title
      font-weight: bold
    .report-card__description
      font-size: 1rem
  .report-card__actions
    display: flex
    gap: 1rem
    .report-card__action
      padding: 0.5rem 1rem
      border-radius: 0.25rem
      background-color: #007bff
      color: white
      cursor: pointer
      transition: background-color 0.2s
      &:hover
        background-color: #0056b3
.report-badge
  top: 5px
  right: 5px
  --border-radius: 10px
  position: absolute
</style>
